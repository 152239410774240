import React, { Component } from 'react';
import 'font-awesome/css/font-awesome.min.css';

export default class Loading extends Component {

    render() {
        return(
            <div id="loading-panel">
                <div className="background"></div>
                <div className="spinner-wrapper">
                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
                </div>
            </div>
        );
    }

}